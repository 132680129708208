import { MarkerClusterGroup } from "leaflet.markercluster";
import { type Location, type Request, ResultFromRequest } from "$lib/Client.ts";
import { setCulture } from "./culture";
import { map, setupMapLayers } from "./map";
import { setupSearch } from "./search";
import { markerForLocation } from "./marker";
import { latLng, latLngBounds, polyline } from "leaflet";
import { RouteColours } from "./setup";

export const RecieveData = (request: Request) => {
    const data = ResultFromRequest(request);
    const routes = new Map<string, Location[]>(Object.entries(data.routes));
    const routeColours = new Map<string, string>();
    map.eachLayer((l) => {
        if (l instanceof MarkerClusterGroup) {
            map.removeLayer(l);
        }
    });
    setCulture(data.culture);
    setupSearch(map);
    setupMapLayers(map);
    let routeIndex = 0;
    const markers = [];
    const focus = [];

    for (const entry of routes) {
        const clusterGroup = new MarkerClusterGroup({ maxClusterRadius: 0 });
        const route: Location[] = entry[1];
        route.sort((a: Location, b: Location) => a.order - b.order);
        const hasTerminator = route.some((l) => l.showAsTerminator);
        for (const loc of route) {
            const marker = markerForLocation(
                route,
                loc,
                routeIndex,
                hasTerminator,
                routeColours,
            );
            // const tempMarker = L.circleMarker(
            //     L.latLng(loc.latitude, loc.longitude),
            // );
            // tempMarker.addTo(map);
            markers.push(latLng(loc.latitude, loc.longitude));
            if (loc.focus) {
                focus.push(latLng(loc.latitude, loc.longitude));
            }
            marker?.addTo(clusterGroup);
        }
        map.addLayer(clusterGroup);
        if (entry[0] !== "-1") {
            routeIndex++;
        }
        // GenerateRegionFromPoints({
        //     color: routeColours.get(route[0].routeId) ?? colours[0],
        //     points: route.map((l) => {
        //         return { latitude: l.latitude, longitude: l.longitude };
        //     }),
        //     name: "",
        // });
    }
    const bounds = latLngBounds(focus.length ? focus : markers);
    map.fitBounds(bounds, { maxZoom: 17 });
    if (RouteColours) {
        RouteColours(routeColours);
    }

    if (data.path) {
        for (const path of Object.entries(data.path)) {
            const line = polyline(path[1], {
                color: routeColours.get(path[0]),
            });
            line.addTo(map);
        }
    }
};
