import DOMPurify from "dompurify";
import parse from "html-react-parser";
import * as preact from "preact";

export const DynamicSVG = ({ src }: { src: string }) => {
    const sanitizedSvg = DOMPurify.sanitize(src, {
        USE_PROFILES: { svg: true },
    });
    const svgElement = parse(sanitizedSvg, { library: preact });

    return <>{svgElement}</>;
};
