import RouteWorker from "$lib/test/route?worker";
import { randomCirclePoint } from "random-location";
import type { Location } from "./Client";
import { map } from "./map";
import { regions } from "./regions";
export async function start(routes: number, amount: number, spread = 4) {
    const radius = 20_000;
    const center = randomCirclePoint(
        {
            latitude: 52,
            longitude: -1.3,
        },
        radius / 10,
    );
    const workers = [];
    for (let route = 0; route < routes; route++) {
        workers.push(
            new Promise((resolve) => {
                const routeCenter = randomCirclePoint(center, radius * spread);
                const worker = new RouteWorker();
                const routeLocations: Location[] = [];
                worker.onmessage = (e) => {
                    if (e.data.type === "TestDataLocation") {
                        routeLocations.push(e.data.data as Location);
                    }
                    if (e.data.type === "TestDataFinished") {
                        resolve(routeLocations);
                    }
                };
                worker.postMessage({ routeCenter, radius, amount, route });
            }),
        );
    }
    return (await Promise.all(workers)).flat();
}
export default { start };
export const regionTest = async () => {
    for (const region of regions.values()) {
        if (region.layer) {
            map.removeLayer(region.layer);
        }
    }
    const locs = await start(5, 30, 1);
    window.postMessage(
        {
            type: "Data",
            data: {
                culture: "fr-fr",
                locations: locs,
            },
        },
        "*",
    );
    window.postMessage(
        {
            type: "Regions",
            data: {
                edit: true,
                regions: [...regions.values()].map((r) => {
                    r.layer = undefined;
                    return r;
                }),
            },
        },
        "*",
    );

    window.postMessage(
        {
            type: "GenerateRegionFromPoints",
            data: { points: locs },
        },
        "*",
    );
};
export const routesTest = async () => {
    window.postMessage(
        {
            type: "Data",
            data: {
                culture: "fr-fr",
                locations: await start(120, 25),
            },
        },
        "*",
    );
};
export const ReceiveTest = async (event: MessageEvent) => {
    if (!event.data.data || event.data.data === "routes") {
        await routesTest();
        return;
    }
    if (event.data.data === "regions") {
        await regionTest();
        return;
    }
};
