import type { CefSharpBound, Region } from "./Client";
import { RecieveData } from "./data";
import { map, setupMapLayers } from "./map";
import {
    ReceiveRegions,
    GenerateRegionFromPoints,
    EnableEditRegionMode,
    EnableDrawRegionMode,
    CancelEditRegionMode,
    SaveRegionEdits,
    RegionNameChange,
    RegionColorChange,
    RegionRoutesChange,
} from "./regions";
import { ReceiveTest } from "./test";
export let PushPinStartDragResultPromise: (value: boolean) => void;
export let DarkMode = false;
export let PushPinStartDrag: () => Promise<boolean>;
export let Selected: (location: string, selected: boolean) => void;
export let PushPinMoved: (id: number, lat: number, lon: number) => void;
export let PushPinCancelDrag: () => void;
export let RouteColours: (routeColours: Map<string, string>) => void;
export let RegionCreated: (region: Region) => void;
export let RegionChanged: (region: Region[]) => void;
export let RegionsReady: () => void;
export let loaded = false;
export const setLoaded = (isLoaded: boolean) => {
    loaded = isLoaded;
};
export const ReceiveSetup = (event: MessageEvent) => {
    console.debug(event.data.darkMode);
    if (event.data.darkMode) {
        document.documentElement.classList.add("dark-mcs-theme");
    } else {
        document.documentElement.classList.remove("dark-mcs-theme");
    }
    DarkMode = event.data.darkMode;

    RouteColours = (routeColours) => {
        event.source?.postMessage(
            { event: "RouteColours", type: "RouteColours", routeColours },
            { targetOrigin: event.origin },
        );
    };
    PushPinMoved = (id, latitude, longitude) => {
        event.source?.postMessage(
            {
                event: "PushPinMoved",
                type: "PushPinMoved",
                latitude,
                longitude,
                id,
            },
            { targetOrigin: event.origin },
        );
    };
    PushPinStartDrag = () => {
        event.source?.postMessage(
            { event: "PushPinStartDrag", type: "PushPinStartDrag" },
            { targetOrigin: event.origin },
        );
        return new Promise((resolve: (value: boolean) => void) => {
            PushPinStartDragResultPromise = resolve;
        });
    };
    Selected = (id, selected) => {
        event.source?.postMessage(
            {
                event: "ClickUnallocatedTask",
                type: "ClickUnalolcatedTask",
                id,
                selected,
            },
            { targetOrigin: event.origin },
        );
    };
    RegionCreated = (region) => {
        event.source?.postMessage(
            {
                type: "RegionCreated",
                region: {
                    name: region.name,
                    color: region.color,
                    points: region.points,
                    decomp: region.decomp,
                },
            },
            { targetOrigin: event.origin },
        );
    };
    RegionChanged = (regions) => {
        event.source?.postMessage(
            {
                type: "RegionsChanged",
                regions: regions.map((region) => ({
                    id: region.id,
                    name: region.name,
                    color: region.color,
                    points: region.points,
                    decomp: region.decomp,
                    routes: region.routes,
                })),
            },
            { targetOrigin: event.origin },
        );
    };
    RegionsReady = () => {
        event.source?.postMessage(
            { type: "RegionsReady" },
            { targetOrigin: event.origin },
        );
    };
    setupMapLayers(map);
};

export const CefSharpSetup = (boundAsync: CefSharpBound) => {
    if (boundAsync.PushPinStartDrag) {
        PushPinStartDrag = boundAsync.PushPinStartDrag;
    }
    if (boundAsync.PushPinMoved) {
        PushPinMoved = boundAsync.PushPinMoved;
    }
    if (boundAsync.PushPinCancelDrag) {
        PushPinCancelDrag = boundAsync.PushPinCancelDrag;
    }
    if (boundAsync.RouteColours) {
        RouteColours = boundAsync.RouteColours;
    }
};
export const setupTaskSelection = () => {
    document.addEventListener("click", (event) => {
        const target = event.target as HTMLElement;
        if (target.matches("[data-select]")) {
            const checkbox = target as HTMLInputElement;
            if (checkbox.dataset.id) {
                const location = checkbox.dataset.id;
                console.debug(
                    `Changed Marker Selection ${location} = ${checkbox.checked}`,
                );
                if (Selected) {
                    Selected(location, checkbox.checked);
                }
            }
        }
    });
};
const OnMessage = async (event: MessageEvent) => {
    console.debug(event.data);
    if (event.data === "PushPinCancelDrag") {
        if (PushPinCancelDrag) {
            PushPinCancelDrag();
            return;
        }
        return;
    }
    if (event.data.event === "PushPinStartDragResult") {
        if (PushPinStartDragResultPromise) {
            PushPinStartDragResultPromise(event.data.result);
            return;
        }
        return;
    }
    switch (event.data.type) {
        case "Test": {
            ReceiveTest(event);
            return;
        }
        case "Setup": {
            ReceiveSetup(event);
            return;
        }
        case "Regions": {
            ReceiveRegions(event.data.data);
            return;
        }
        case "Data": {
            RecieveData(event.data.data);
            return;
        }
        case "GenerateRegionFromPoints": {
            GenerateRegionFromPoints(event.data.data);
            return;
        }
        case "EnableEditRegionMode": {
            EnableEditRegionMode();
            return;
        }
        case "EnableDrawRegionMode": {
            EnableDrawRegionMode();
            return;
        }
        case "CancelEditRegionMode": {
            CancelEditRegionMode();
            return;
        }
        case "SaveRegionEdits": {
            SaveRegionEdits();
            return;
        }
        case "RegionNameChange": {
            RegionNameChange(event.data);
            return;
        }
        case "RegionColorChange": {
            RegionColorChange(event.data);
            return;
        }
        case "RegionRoutesChange": {
            RegionRoutesChange(event.data);
            return;
        }
    }
};
export const setupHostIntegrations = () => {
    if (!loaded) {
        window.addEventListener("message", OnMessage);
        if (window.CefSharp) {
            window.CefSharp.BindObjectAsync("boundAsync").then(() => {
                if (window.boundAsync) {
                    CefSharpSetup(window.boundAsync);
                }
            });
        }
        setLoaded(true);
    }
};
