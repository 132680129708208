import baseIcon from "$icons/base.svg?raw";
import search_icon from "$icons/circle.svg?raw";
import L from "leaflet";
import { search } from "./components/search";
import { culture } from "./culture";
import { bingKey } from "./keys";
import { regions } from "./regions";
let searchControl: L.Control | undefined;
let searchMarker: L.Marker | undefined;
export const setupSearch = (map: L.Map) => {
    if (searchControl) {
        searchControl.remove();
    }
    searchControl = search({
        culture: culture.toLowerCase(),
        position: "topright",
        onSearch: async (q) => {
            const resp = await fetch(
                `https://dev.virtualearth.net/REST/v1/Locations?query=${q}&key=${bingKey}`,
            );
            const result = await resp.json();
            if (result.resourceSets && result.resourceSets.length > 0) {
                const resourceSet = result.resourceSets[0];
                if (resourceSet.resources && resourceSet.resources.length > 0) {
                    const resource = resourceSet.resources[0];
                    const iconOptions: L.IconOptions = {
                        iconUrl: `data:image/svg+xml,${baseIcon
                            .replaceAll("{icon}", search_icon)
                            .replaceAll("{color}", "#000000")
                            .replaceAll("{text}", "")
                            .replaceAll("{textColor}", "#ffffff")
                            .replace(/"/g, "'")
                            .replace(/%/g, "%25")
                            .replace(/#/g, "%23")
                            .replace(/{/g, "%7B")
                            .replace(/}/g, "%7D")
                            .replace(/</g, "%3C")
                            .replace(/>/g, "%3E")
                            .replace(/\s+/g, " ")}`,
                        iconSize: [27, 41],
                        iconAnchor: [13, 41],
                        popupAnchor: [1, -34],
                        tooltipAnchor: [16, -28],
                    };
                    const lat = resource.geocodePoints[0].coordinates[0];
                    const lon = resource.geocodePoints[0].coordinates[1];
                    searchMarker?.remove();
                    searchMarker = L.marker([lat, lon], {
                        icon: L.icon(iconOptions),
                    });
                    const region = [...regions.values()].find((r) => {
                        if (r.decomp) {
                            return r.decomp.some((p) =>
                                inside({ latitude: lat, longitude: lon }, p),
                            );
                        }
                        return inside(
                            { latitude: lat, longitude: lon },
                            r.points,
                        );
                    });
                    if (region) {
                        console.info("Marker found in: ", region.name);
                    } else {
                        console.info("Marker not in a region");
                    }
                    const bounds = new L.LatLngBounds([[lat, lon]]);
                    searchMarker.addTo(map);
                    map.fitBounds(bounds, { maxZoom: 17 });
                }
            }
        },
    });
    searchControl.addTo(map);
};
const inside = (
    point: { latitude: number; longitude: number },
    vs: { latitude: number; longitude: number }[],
) => {
    // ray-casting algorithm based on
    // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html

    const x = point.latitude;
    const y = point.longitude;

    let inside = false;
    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        const xi = vs[i].latitude;
        const yi = vs[i].longitude;
        const xj = vs[j].latitude;
        const yj = vs[j].longitude;

        const intersect =
            yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) inside = !inside;
    }

    return inside;
};
