import type { Location } from "../Client";
export const Popup = ({ loc }: { loc: Location }) => {
    return (
        <pre class="popup">
            {loc.routeId === "-1" ? (
                <input type="checkbox" data-id={loc.id} data-select />
            ) : (
                ""
            )}{" "}
            {loc.text}
        </pre>
    );
};
