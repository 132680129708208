import Icon from "$icons/search.svg?react";
import i18n from "$lib/i18n";
import { Control, type ControlOptions } from "leaflet";
import { render } from "preact";
import { useState } from "preact/hooks";
export const Search = Control.extend({
    options: {
        culture: "en-gb",
        position: "topright",
        onSearch: async (q: string) => {
            console.info(q);
        },
    },
    onAdd() {
        const container = document.createElement("div");
        render(
            <SearchBox
                culture={this.options.culture}
                onSearch={this.options.onSearch}
            />,
            container,
        );
        return container.children.item(0);
    },
});
const SearchBox = ({
    culture,
    onSearch,
}: {
    culture: string;
    onSearch: (q: string) => Promise<void>;
}) => {
    const [placeholder, setPlaceholder] = useState("Search Address");
    const [searchValue, setSearchValue] = useState("");
    const [expanded, setExpanded] = useState(false);
    let timeout: NodeJS.Timeout | undefined;
    i18n.get(culture).then((translations) => {
        setPlaceholder(translations.search);
    });
    const submit = (event: SubmitEvent) => {
        event.preventDefault();
        onSearch(searchValue);
    };
    const onInput = (event: InputEvent) => {
        const value = (event.currentTarget as HTMLInputElement).value;
        setSearchValue(value);

        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            onSearch(value);
        }, 500);
    };
    const toggle = () => {
        setExpanded(!expanded);
    };
    return (
        <div class="leaflet-control-search leaflet-control">
            <button
                type="button"
                class="leaflet-control-search-toggle"
                onClick={toggle}
            >
                <Icon />
            </button>
            <form class={expanded ? "expanded" : ""} onSubmit={submit}>
                <input
                    name="search"
                    value={searchValue}
                    onInput={onInput}
                    placeholder={placeholder}
                />
            </form>
        </div>
    );
};

export const search = (
    options: ControlOptions & {
        onSearch?: (q: string) => Promise<void>;
        culture: string;
    },
) => {
    return new Search(options);
};
