import "./style.css";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "leaflet";
import "leaflet-plugins/layer/tile/Bing.js";
import "mapbox-gl-leaflet/leaflet-mapbox-gl.js";
import { setupMap } from "$lib/map";
import { setupHostIntegrations, setupTaskSelection } from "$lib/setup";
setupMap();
setupTaskSelection();
setupHostIntegrations();
