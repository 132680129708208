export const get = async (culture: string) => {
    let translations: Record<string, string>;
    console.debug("Trying to get translations for", culture);
    try {
        translations = await import(
            `./translations-${culture || "en-gb"}.json`
        );
    } catch {
        console.debug("Falling back to en-gb");
        translations = (await import(
            "./translations-en-gb.json"
        )) as unknown as Record<string, string>;
    }
    return translations;
};
export default { get };
