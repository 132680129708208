//import { LatLng } from "leaflet";
import type { Polygon } from "leaflet";
export interface Result {
    routes: object;
    path?: Map<string, number[][]>;
    culture: string;
}
export interface Request {
    locations: Location[];
    path: Map<string, number[][]>;
    culture: string;
}
export interface Location {
    routeId: string;
    longitude: number;
    latitude: number;
    text: string;
    icon?: number;
    order: number;
    pushpinText: PushPinText;
    showAsTerminator: boolean;
    draggable: boolean;
    id: number;
    color?: string;
    focus: boolean;
}
export enum PushPinText {
    None = 0,
    Order = 1,
    Full = 2,
}
export interface Region {
    decomp?: { longitude: number; latitude: number }[][];
    points: { longitude: number; latitude: number }[];
    name: string;
    color: string;
    id?: number;
    layer?: Polygon;
    routes?: RegionRoute[];
}
export interface Regions {
    regions: Region[];
    edit: boolean;
}
export interface RegionRoute {
    ID: number;
    MappingRegionID: number;
    CalendarScheduleRouteId: number;
    RollingScheduleRouteId: number;
    IsNew: boolean;
    Deleted: boolean;
}
export interface CefSharpBound {
    PushPinStartDrag?: () => Promise<boolean>;
    Selected?: (location: string, selected: boolean) => void;
    PushPinMoved?: (id: number, lat: number, lon: number) => void;
    PushPinCancelDrag?: () => void;
    RouteColours?: (routeColours: Map<string, string>) => void;
    RegionCreated?: (region: Region) => void;
    RegionChanged?: (region: Region[]) => void;
}

export function ResultFromRequest(r: Request): Result {
    const routes = r.locations.reduce(
        (g: Map<string, Location[]>, current: Location) => {
            if (!current.latitude) {
                current.latitude = 0;
            }
            if (!current.longitude) {
                current.longitude = 0;
            }
            if (g.has(current.routeId)) {
                g.get(current.routeId)?.push(current);
            } else {
                g.set(current.routeId, [current]);
            }

            return g;
        },
        new Map<string, Location[]>(),
    );
    return {
        routes: Object.fromEntries(routes),
        path: r.path,
        culture: r.culture,
    };
}
