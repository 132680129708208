import circle from "$icons/circle.svg?raw";
import collection from "$icons/collection.svg?raw";
import delivery from "$icons/delivery.svg?raw";
import exchange from "$icons/exchange.svg?raw";
import hexagon from "$icons/hexagon.svg?raw";
import pentagon from "$icons/pentagon.svg?raw";
import rectangle from "$icons/rectangle.svg?raw";
import star from "$icons/star.svg?raw";
import timesheet from "$icons/timesheet.svg?raw";
import toilet from "$icons/toilet.svg?raw";
import triangle from "$icons/triangle.svg?raw";
import workshop from "$icons/workshop.svg?raw";
const icons = [
    circle,
    hexagon,
    pentagon,
    rectangle,
    star,
    triangle,
    delivery,
    collection,
    toilet,
    exchange,
    workshop,
    "",
    timesheet,
    timesheet,
];
export default icons;
