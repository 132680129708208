import { DynamicSVG } from "./dynamic-svg";

export const ExpandedIcon = ({
    icon,
    pinColor,
    text,
}: { icon: string; pinColor: string; text: string }) => {
    return (
        <div class="div-icon" style={`--border-color:${pinColor}`}>
            {icon ? (
                <div class="div-icon-icon">
                    <DynamicSVG src={icon.replaceAll("{color}", pinColor)} />
                </div>
            ) : (
                <></>
            )}
            <span style="align-self:center;color:#000000">{text}</span>
        </div>
    );
};
