import i18n from "$lib/i18n";
import { useState } from "preact/hooks";
import colours from "../colours.json";
export const RegionModal = ({ culture }: { culture: string }) => {
    const [nameLabel, setNameLabel] = useState("");
    const [colorLabel, setColorLabel] = useState("");
    const [saveLabel, setSaveLabel] = useState("");
    const [cancelLabel, setCancelLabel] = useState("");
    i18n.get(culture).then((translations) => {
        setNameLabel(translations.region_name);
        setColorLabel(translations.region_color);
        setSaveLabel(translations.region_save);
        setCancelLabel(translations.region_cancel);
    });

    return (
        <dialog id="region_options" class="modal">
            <form>
                <label>
                    <fieldset>
                        <legend>{nameLabel}</legend>
                        <input
                            name="name"
                            autocomplete="off"
                            placeholder={nameLabel}
                            required
                        />
                    </fieldset>
                </label>
                <label>
                    <fieldset>
                        <legend>{colorLabel}</legend>
                        <input
                            name="color"
                            type="color"
                            list="regionSwatches"
                            required
                        />
                        <datalist id="regionSwatches">
                            {colours.map((colour) => (
                                <option value={colour} key={colour} />
                            ))}
                        </datalist>
                    </fieldset>
                </label>
                <div class="region-buttons">
                    <button type="submit" class="save">
                        {saveLabel}
                    </button>
                    <button type="button" class="cancel">
                        {cancelLabel}
                    </button>
                </div>
            </form>
        </dialog>
    );
};
