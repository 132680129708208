import wideIcon from "$icons/base-wide.svg?raw";
import baseIcon from "$icons/base.svg?raw";
import { type Location, PushPinText } from "$lib/Client";
import colours from "$lib/colours.json";
import color from "color";
import L from "leaflet";
import renderToString from "preact-render-to-string";
import { ExpandedIcon } from "./components/expanded-icon";
import { Popup } from "./components/popup";
import icons from "./icons";
import { PushPinMoved, PushPinStartDrag } from "./setup";
export interface MapMarker {
    location: Location;
    text: string;
    options?: MapMarkerOptions;
    color?: string;
    borderColor?: string;
    textColor?: string;
    icon?: string;
    selectable?: boolean;
    onClick?: () => void;
    draggable?: boolean;
}
export interface MapMarkerOptions {
    iconUrl: string;
    iconSize: number[];
    iconAnchor: number[];
    popupAnchor: number[];
    tooltipAnchor: number[];
}

export const getMarkerText = (
    loc: Location,
    hasTerminator: boolean,
    i: number,
) => {
    if (loc.pushpinText === PushPinText.Order) {
        if (!loc.showAsTerminator) {
            return (+i + (hasTerminator ? 0 : 1)).toString();
        }
    } else if (loc.pushpinText === PushPinText.Full) {
        return loc.text;
    }
    return "";
};

const AddPopup = (marker: L.Marker, loc: Location) => {
    const popup = L.popup({ autoClose: false });
    popup.setContent(renderToString(<Popup loc={loc} />));
    marker.bindPopup(popup);
};
const buildIconUrl = (
    text: string,
    icon: string,
    pinColor: string,
    textColor: string,
) => {
    return `data:image/svg+xml,${(text ? wideIcon : baseIcon)
        .replaceAll("{icon}", icon)
        .replaceAll("{color}", pinColor)
        .replaceAll("{text}", text)
        .replaceAll("{textColor}", textColor)
        .replace(/"/g, "'")
        .replace(/%/g, "%25")
        .replace(/#/g, "%23")
        .replace(/{/g, "%7B")
        .replace(/}/g, "%7D")
        .replace(/</g, "%3C")
        .replace(/>/g, "%3E")
        .replace(/\s+/g, " ")}`;
};
const AddStandardMarker = (
    text: string,
    icon: string,
    pinColor: string,
    textColor: string,
    loc: Location,
) => {
    const iconOptions = {
        iconUrl: buildIconUrl(text, icon, pinColor, textColor),
        iconSize: L.point(text ? 41 : 27, 41),
        iconAnchor: L.point(text ? 20 : 13, 41),
        popupAnchor: L.point(1, -34),
        tooltipAnchor: L.point(16 - 28, 0),
    };
    const marker = L.marker([loc.latitude, loc.longitude], {
        icon: L.icon(iconOptions),
        draggable: loc.draggable,
    });
    marker.on("dragstart", onDragStart);
    marker.on("move", (e) => onMarkerMove(e as L.LeafletMarkerMoveEvent));
    if (loc.text) {
        AddPopup(marker, loc);
    }
    return marker;
};
const onMarkerMove = (e: L.LeafletMarkerMoveEvent) => {
    //console.debug(e);
    if (PushPinMoved) {
        PushPinMoved(e.target.options.id, e.latlng.lat, e.latlng.lng);
    }
};
const onDragStart = async (e: L.LeafletEvent) => {
    if (PushPinStartDrag) {
        const result = await PushPinStartDrag();
        if (!result) {
            e.sourceTarget.dragging.disable();
        }
    }
};
export const markerForLocation = (
    route: Location[],
    loc: Location,
    routeIndex: number,
    hasTerminator: boolean,
    tempRouteColours: Map<string, string>,
): L.Marker | undefined => {
    const i = loc.order;
    if (loc.showAsTerminator && i === route.length - 1) {
        return;
    }
    tempRouteColours.set(
        loc.routeId,
        loc.routeId === "-1" ? "#000000" : colours[routeIndex % colours.length],
    );
    const icon =
        icons[
            loc.icon !== null && loc.icon !== undefined
                ? loc.icon
                : Math.floor(routeIndex / colours.length) % 6
        ];
    let textColor = "#000000";
    let pinColor: string;
    if (loc.color) {
        pinColor = loc.color;
    } else if (loc.showAsTerminator) {
        pinColor = "green";
    } else {
        pinColor = tempRouteColours.get(loc.routeId) ?? colours[0];
    }

    if (color(pinColor).luminosity() < 0.5) {
        textColor = "#ffffff";
    }
    const text: string = getMarkerText(loc, hasTerminator, i);
    if (loc.pushpinText !== PushPinText.Full) {
        return AddStandardMarker(text, icon, pinColor, textColor, loc);
    }
    const div_icon = L.divIcon({
        className: "my-div-icon",
        html: renderToString(
            <ExpandedIcon pinColor={pinColor} icon={icon} text={loc.text} />,
        ),
    });
    const marker = L.marker([loc.latitude, loc.longitude], {
        icon: div_icon,
    });
    return marker;
};
